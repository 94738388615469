<template>
  <section id="card-actions">
    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="configurations"
          action-collapse
          title="Configurazioni"
        >
          <app-collapse
            accordion
          >
            <app-collapse-item
              v-for="(m, idx) in activeModules"
              :key="idx"
              :title="m.name"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    v-for="(c, idy) in portalConfigs.filter(x => x.Module == m.name)"
                    :key="idy"
                    :label="c.Description"
                    :label-for="c.Code"
                  >
                    <b-form-input
                      v-if="!isJson(c.ValidValues)"
                      v-model="c.Value"
                      :name="c.Code"
                      :placeholder="c.Description"
                    />
                    <v-select
                      v-else
                      v-model="c.Value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="JSON.parse(c.ValidValues)"
                      label="label"
                      index="value"
                      :reduce="val => val.value"
                      :clearable="false"
                      :input-id="c.Code"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item
              v-if="userData.id == 1"
              key="APP"
              title="APP"
            >
              <b-row>
                <b-col cols="12">
                  <!--olny super user-->
                  <b-form-group
                    v-for="(c, idy) in portalConfigs.filter(x => x.Module == 'APP')"
                    :key="idy"
                    :label="c.Description"
                    :label-for="c.Code"
                  >
                    <b-form-input
                      v-if="!isJson(c.ValidValues)"
                      v-model="c.Value"
                      :name="c.Code"
                      :placeholder="c.Description"
                    />
                    <v-select
                      v-else
                      v-model="c.Value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="JSON.parse(c.ValidValues)"
                      label="label"
                      index="value"
                      :reduce="val => val.value"
                      :clearable="false"
                      :input-id="c.Code"
                    />
                  </b-form-group>
                  <!--only super user-->
                </b-col></b-row>
            </app-collapse-item>
          </app-collapse>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="saveChanges()"
          >
            Salva
          </b-button>
        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import {
  BRow, BCol, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import axios from '@axios'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const licenseInfo = ref({})
    const portalConfigs = ref([])
    const activeModules = ref([])
    const userData = getUserData()
    onMounted(() => {
      axios
        .get(`/base/getlicinfo`)
        .then(response => {
          licenseInfo.value = response.data.Data
          activeModules.value = response.data.Data.modules.filter(x => x.active == 'Y')
          axios
            .get(`/base/getportalconfigs`)
            .then(res => {
              // activeModules.value = licenseInfo.value.modules.filter(x => x.active == 'Y')
              portalConfigs.value = res.data.Data.sort((a, b) => (a.Module > b.Module ? 1 : -1))
            })
            .catch(error => {
            })
        })
        .catch(error => {
        })
    })

    return {
      portalConfigs,
      licenseInfo,
      activeModules,
      userData,
    }
  },
  data() {
    return {
      forms: {
        licenseKey: '',
        standardBaseUrl: '',
      },
    }
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    resolveVariantColor(isactive) {
      if (isactive == 'Y') {
        return 'success'
      }
      return 'danger'
    },
    saveChanges() {
      axios
        .post(`/base/saveportalconfigs`, {
          configs: this.portalConfigs,
        })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Company Configs Updated',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: ``,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Company Configs Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: ``,
            },
          })
        })
      // this.$router.go()
    },
  },
}
</script>
